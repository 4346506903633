
import { defineComponent, onMounted } from "vue";
import ClientClaimsTable from "@/components/individual/ClientClaimsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default defineComponent({
  name: "underwriter-client-claims-listing",
  components: { ClientClaimsTable },
  data() {
    return {
      claimsSummary: {},
      claims: [],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("All Claims", ["Clients", "Claims"]);
      MenuComponent.reinitialization();
    });
  },
  created() {
    // Get client filed claims
    this.getClientsClaims();

    //Set page title
    document.title = "All Clients Claims | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClientsClaims() {
      //Fetch client filed claims
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.UNDERWRITERS_CLIENT_CLAIMS_ROUTE)
          .then(({ data }) => {
            //Assign data to props
            this.claimsSummary = data.data.claimsSummary;
            this.claims = data.data.claims;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
